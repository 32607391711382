import React from 'react';

export const Pay = (activateNextTab, stateManager, activeTab, state) => {
    return (<div>
      <p className='text-center'>Ваш счет сформирован, для оплаты заказа пожалуйста, перейдите по ссылке ниже</p>
      <table id='table-info'>
      <tbody>
        <tr>
          <td className='light-row'>Имя</td><td className='light-row'>{state.user.name}</td>
        </tr>
        <tr>
          <td className='dark-row'>Фамилия</td><td className='dark-row'>{state.user.secondName}</td>
        </tr>
        <tr>
          <td className='light-row'>Ваш Телефон</td><td className='light-row'>{state.user.phone}</td>
        </tr>
        {state.orderOneCity.active ?
          (<tr>
            <td  className='dark-row'>Город Инкассации: </td><td  className='dark-row'>{state.showCity}</td>
          </tr>)
          :
          (<tr>
            <td  className='dark-row'>Города перевозки: </td><td  className='dark-row'>{state.showCity}</td>
          </tr>)
        }
        <tr>
          <td className='light-row'>Цена</td><td className='light-row'>{state.amount} грн.</td>
        </tr>
      </tbody>
      </table>
      <h3 className='text-center'><a href={state.paymentLink} target='_blank'  rel="noopener noreferrer">Страница оплаты</a></h3>
      <p>Оплачивая счет, Вы соглашаетесь с <a href='http://goo.gl/i6kErV' target='_blank'  rel="noopener noreferrer">публичной офертой</a> CIT Security о предоставлении услуг инкассации</p>
      {state.orderOneCity.active ?
        (<p>Бронетакси работает в пределах города на протяжении двух часов. Дополнительно время оплачивается отдельно.</p>):
        (<p>При отсутствии свободных машин в выбранном городе может взыматься дополнительная плата.</p>)}
    </div>)
};


