import React from 'react';

export const Welcome = (activateNextTab, stateManager, activeTab, state) => {
  function selectMultiCity() {
    stateManager(
      {orderMultiCity: {...state.orderMultiCity, active: true},
        orderOneCity: {...state.orderOneCity, active: false},
      activeTab: 1 + activeTab})
  }

  function selectCity() {
   stateManager(
     {orderOneCity: {...state.orderOneCity, active: true},
       orderMultiCity: {...state.orderMultiCity, active: false},
     activeTab: 1 + activeTab})
  }

  return (<div>
    <p>Вас приветствует сервис <b>бронетакси от CIT Security</b>.</p>
    <p>Пожалуйста выберите вид перевозки.</p>
    <p className='text-center'>
      <button className='accept_data'
              role='tab'
              onClick={ selectCity }>
              Инкассация по городу
      </button>
      <button className='accept_data'
              role='tab'
              onClick={ selectMultiCity }>
              Межгородская инкассация
      </button>
    </p>
  </div>)
};
