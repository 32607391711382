import axios from 'axios';
import qs from 'qs';

export function sendMailFromState(user, price, city) {
  const name = user.name + ' ' + user.secondName;
  const body = {...user, ...city, price, name};
  axios.post('https://bronetaxi.cit.net.ua/m/',
    qs.stringify(body),
    { headers: {'Content-Type': 'application/x-www-form-urlencoded'}}
  )
}
