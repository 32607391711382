import axios from "axios";
import {ORDER_ENDPOINT} from "../constans";
import {NotificationManager} from "react-notifications";

export function activeOrderServer() {
  axios.get(ORDER_ENDPOINT);
}

export function getPaymentOrder(name, amount) {
  return new Promise((res, rej) => {
    console.log(name, amount);
    axios.post(ORDER_ENDPOINT + 'form-payment', {
      name,
      amount: +amount
    },{ headers: { 'Access-Control-Allow-Origin': '*' } })
      .then(function (response) {
        res(response.data.order);
      })
      .catch(function (error) {
        NotificationManager.info('Не верно заполненные поля!', '', 3000);
      });
  })
}

