import React from 'react';
import {Panel} from "./panel";
import {Content} from "./content";
import {stateManager} from "../state-util";
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import {LOGO_SRC} from "../constans";


export class Accordion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
      orderMultiCity: {
          active: false
      },
      orderOneCity: {
          active: false
      },
      user: {
        name: '',
        secondName: '',
        address: '',
        phone: '+380'
      }
    };

    this.activateTab = this.activateTab.bind(this);
    this.activateNextTab = this.activateNextTab.bind(this);
  }

  activateTab(index) {
    this.setState(prev => {
      return {activeTab: prev.activeTab === index ? -1 : index}
    });
  }

  activateNextTab() {
    this.setState(prev => {
      return {activeTab: prev.activeTab + 1}
    });
  }

  alertNoInputs(label) {
    NotificationManager.info('Пожалуйста, заполните все поля', '', 3000);
  }

  render() {
    const panels  = Content;
    const { activeTab } = this.state;

    return (
      <div className='accordion' role='tablist'>
        <p className='text-center'>
          <a href="https://cit.net.ua" target='_blank' rel="noopener noreferrer">
            <img src={LOGO_SRC} alt="CIT SECURITY"/>
          </a>
        </p>
        {panels.map((panel, index) => {
          let styleTab = 'panel-now';
          if (index < activeTab) {
            styleTab = 'panel-active';
          }
          if (index > activeTab) {
            styleTab = 'panel-passive';
          }
          return <Panel
              key={index}
              activeTab={activeTab}
              index={index}
              {...panel}
              activateTab={index < activeTab ? this.activateTab.bind(null, index) : () => this.alertNoInputs(panel.label)}
              styleTab={styleTab}
              activateNextTab={this.activateNextTab}
              stateManager={stateManager(this)}
              state={this.state}
            />
          }
        )}
        <NotificationContainer/>
      </div>
    );
  }
}
