import React from 'react';
import Select from 'react-select';
import {NotificationManager} from "react-notifications";
import {activeOrderServer} from "../../utils/order-server";
import {optionsMultiCity, optionsCity} from "../../constans";

export const CitySelect = (activateNextTab, stateManager, activeTab, state) => {
  if (state.orderOneCity.active) {
    let city = state.orderOneCity.city || null;

    return (<div className='city-frame'>
      <div className="one-city">
      <div className='city-column'>
        <h4>Выберете город инкассации</h4>
        <div className='city-options'>
          <Select
            autocomplete="off"
            value={city}
            onChange={handleChangeCity}
            options={optionsCity}
            placeholder="Город..."
          />
        </div>
      </div>
      </div>
      <div className='text-center'>
        <button className='accept_data'
                role='tab'
                onClick={ selectCity }>
                Готово
        </button>
      </div>
    </div>)
  }

  if (state.orderMultiCity.active) {
    let cityInvoice = state.orderMultiCity.cityInvoice || null;
    let cityAccept = state.orderMultiCity.cityAccept || null;
    return (<div className='city-frame'>
      <div className="multi-city">
      <div className='city-column'>
        <h4>Выберете город отправки</h4>
        <div className='city-options'>
          <Select
            autocomplete="off"
            value={cityInvoice}
            onChange={handleChangeMultiCityInvoice}
            options={optionsMultiCity}
            placeholder="Город..."
          />
        </div>
      </div>

      <div className='city-column'>
        <h4>Выберете город доставки</h4>
        <div className='city-options'>
          <Select
            autocomplete="off"
            value={cityAccept}
            onChange={handleChangeMultiCityAccept}
            options={optionsMultiCity}
            placeholder="Город..."
          />
        </div>
      </div>
      </div>
      <p className='text-center'>
        <button className='accept_data'
                role='tab'
                onClick={ selectCity }>
                Готово
        </button>
      </p>
    </div>)
  }

  function selectCity() {
    if (state.orderOneCity.city && state.orderOneCity.active) {
      activeOrderServer();
      stateManager({showCity: state.orderOneCity.city.label, activeTab: 1 + activeTab});
    } else if (state.orderMultiCity.cityInvoice && state.orderMultiCity.cityAccept && state.orderMultiCity.active){
      if (state.orderMultiCity.cityInvoice.value === state.orderMultiCity.cityAccept.value) {
        NotificationManager.info('Города не могут совпадать', '', 3000);
      } else {
        activeOrderServer();
        stateManager({showCity:`${state.orderMultiCity.cityInvoice.label} - ${state.orderMultiCity.cityAccept.label}`, activeTab: 1 + activeTab})
      }
    } else {
      NotificationManager.info('Пожалуйста заполните все поля', '', 3000);
    }
  }

  function handleChangeMultiCityInvoice(selectedOption) {
    stateManager({ orderMultiCity: {...state.orderMultiCity, cityInvoice: selectedOption} });
  }

  function handleChangeMultiCityAccept(selectedOption) {
    stateManager({ orderMultiCity: {...state.orderMultiCity, cityAccept: selectedOption} });
  }

  function handleChangeCity(selectedOption) {
    stateManager({ orderOneCity: {...state.orderOneCity, city: selectedOption} });
  }

};



