import {Welcome} from "./welcome";
import {PersonalDataForm} from "./personal-data-form";
import {CitySelect} from "./city-select";
import {Pay} from "./pay";

export const Content = [
  {
    label: 'Выбор услуги',
    content: Welcome
  },
  {
    label: 'Выбор города',
    content: CitySelect
  },
  {
    label: 'Заявка',
    content: PersonalDataForm
  },
  {
    label: 'Оплата',
    content: Pay
  }
];
