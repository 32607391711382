import React from 'react';
import InputMask from 'react-input-mask';
import {NotificationManager} from 'react-notifications';
import {sendMailFromState} from "../../utils/mailer";
import {getPaymentOrder} from "../../utils/order-server";
import {Cities, COEFFICIENT_ONE_CITY} from "../../constans";

export const PersonalDataForm = (activateNextTab, stateManager, activeTab, state) => {
 const {name, secondName, address} = state.user || {name: '', secondName: '', address: ''};
 const phone = state.user.phone || '+380';

 return (<div>
      <div className='text-center'>
        <p className='field-form'>
          <label className=''>Имя</label>
          <input
            type="text"
            value={name}
            name='name'
            onChange = {(e) => addFormValue({name: e.target.value})
            }/>
        </p>
        <p className='field-form'>
          <label>Фамилия</label>
          <input
            type="text"
            value={secondName}
            onChange = {(e) => addFormValue({secondName: e.target.value})} />
        </p>
        <p className='field-form'>
          <label>Адрес подачи</label>
          <input
            type="text"
            value={address}
            name='address'
            onChange = {(e) => addFormValue({address: e.target.value})}
          />
        </p>
        <p className='field-form'>
          <label>Телефон</label>
          <InputMask
            mask="+380\ (99) 999 99 99"
            maskChar=" "
            value={phone}
            name='phone'
            onChange={(e) => addFormValue({phone: e.target.value})} />
        </p>
        <div className='text-center'>
          <button className='accept_data'
                  role='tab'
                  onClick={ () => {sendData(state)} }>
                  Готово
          </button>

        </div>
        <br/>
        <br/>
      </div>
    </div>);

  function sendData(state) {
    const {name, secondName, phone, address} = state.user;
    const city = {};

    if ( !name || name.length < 3) {
      NotificationManager.info('Введите корректное имя', '', 3000);
      return
    }

    if ( !secondName || secondName.length < 3) {
      NotificationManager.info('Введите корректную фамилию', '', 3000);
      return
    }

    if (!address || address.length <= 4 ) {
      NotificationManager.info('Введите корректный адрес', '', 3000);
      return
    }

    if (!phone || phone.length < 19 || phone[phone.length - 1] === ' ') {
      NotificationManager.info('Введите телефон', '', 3000);
      return
    }

    if (state.orderOneCity.active) {
      city.city_one = state.orderOneCity.city.label;
    } else {
      city.city_one = state.orderMultiCity.cityInvoice.label;
      city.city_two = state.orderMultiCity.cityAccept.label;
    }

    const amount = getPriceByState(state);
    getPaymentOrder(name + ' ' + secondName, amount)
      .then( paymentLink => {
        sendMailFromState(state.user, amount, city);
        stateManager({amount, paymentLink, activeTab: 1 + activeTab})})
  }

  function addFormValue(value = {}) {
    stateManager({ user: { ...state.user, ...value }})
  }


  function getPriceByState(state) {
    if (state.orderOneCity.active) {
      return + (state.orderOneCity.city.value.toString().split('.')[0]);
    } else {
      return Math.round(Cities[state.orderMultiCity.cityInvoice.value][state.orderMultiCity.cityAccept.value] * COEFFICIENT_ONE_CITY);
    }
  }
};




